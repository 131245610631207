import { WidgetCreatorParams, widgetScriptBuilder } from '@wix/bob-widget-services';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

function createWidget() {
  return {
    pageReady: async () => {},
  };
}

const widgetViewerController = widgetScriptBuilder()
  .withCreateMethod(createWidget)
  .build();

export default ({
  controllerConfig,
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
  controllerConfig: WidgetCreatorParams;
}) => {
  return widgetViewerController(controllerConfig, flowAPI);
};
